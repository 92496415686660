<template>
  <v-autocomplete
    ref="auto"
    placeholder="Seleccione una Clasificación Contable"
    :label="toInvoices ? 'Clasificación Contable' : 'Clasificación Contable Padre'"
    item-text="name"
    persistent-hint
    :loading="loading"
    item-value="id"
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :items="classificationList"
    :rules="rules"
    return-object
    :search-input.sync="search"
    :readonly="disabled"
  ></v-autocomplete>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";

export default {
  // props: ["required", "value", "reset","edit"],
  props: {
    required: { type: Boolean, required: false },
    value: { type: Object, required: false },
    reset: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false, default: false },
    itemText: { type: Boolean, required: false, default: false },
    toInvoices: { type: Boolean, required: false, default: true },
    toLevelFilter: { type: Number, required: false },
  },
  data() {
    return {
      classificationList: [],
      parent: 3,
      loading: false,
      search: "",
      //rules
      rules: [],
    };
  },
  methods: {
    getClassification(param = "") {
      this.loading = true;
      let uri = "/api/accounting/classification/";
      if (this.toInvoices) {
        uri += "?code_filter__in=5,6,1,2&level=4";
      }
      if (this.toLevelFilter) {
        uri += "?level=" + this.parent;
      }
      if (this.search) {
        if (this.toLevelFilter || this.toInvoices) {
          uri += "&search=";
        } else {
          uri += "?search=";
        }
        uri += param;
      }
      requests.get(uri).then((res) => {
        this.loading = false;
        this.classificationList = res.data
      });
    },
  },
  mounted() {
    this.getClassification();
    if (this.required == null) {
      this.rules = [];
    } else {
      this.rules = [rules.required];
    }
  },
  watch: {
    toInvoices() {
      this.getClassification();
    },
    toLevelFilter() {
      if ([2, 3, 4].includes(this.toLevelFilter)) {
        this.parent = this.toLevelFilter - 1;
      }
      this.getClassification();
    },
    reset: function () {
      this.$refs.auto.reset();
      this.getClassification();
    },
    search: function () {
      this.getClassification(this.search);
    },
  },
};
</script>
